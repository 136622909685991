import styles from "../../styles/partials/_popup.scss";
import CTA from "./CTA";
import { _class } from "../utilities/helpers";
import Modal from "../widgets/Modal";
import Image from "../widgets/Image";
import Hamburger from "../partials/Hamburger";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "popup");

class Popup extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
    CONTENT: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.page = (this.props.CONTENT.byTemplate("popup") || [])[0];
    this.LANG = new Translation(props.lang);

    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    // setTimeout(
    //   () =>
    //     this.setState({
    //       visible: !window.location.pathname.match("/checkout"),
    //     }),
    //   500
    // );
  }

  render() {
    if (!this.page) {
      return null;
    }

    return (
      <div className={cl("")}>
        <Modal open={this.state.visible}>
          <div className={cl("container")}>
            <div className={cl("inner")}>
              {this.page.image1 && (
                <div className={cl("inner__left")}>
                  <Image
                    src={this.page.image1}
                    alt={this.page.image1_alt_text}
                  />
                </div>
              )}
              <div className={cl("inner__right")}>
                <div className={cl("close")}>
                  <Hamburger
                    open={true}
                    onClick={() => this.setState({ visible: false })}
                    color={"black"}
                  />
                </div>
                <div>
                  <h2>
                    {this.props.lang === "en" ? this.page.h1 : this.page.h2}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.lang === "es"
                          ? this.page.blurb2
                          : this.page.blurb1,
                    }}
                  />
                  <div className={cl("cta")}>
                    <CTA
                      text={
                        this.props.lang === "es" ? "Aprende Más" : "Learn More"
                      }
                      link={this.page.h3}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Popup;
