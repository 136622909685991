import Loadable from "react-loadable";
import LoadableVisibility from "react-loadable-visibility/react-loadable";
import browser from "bowser";
import "focus-visible";
import { sleep, gaTrackEvent } from "../utilities/helpers";
import { loadingDuration } from "../config/appConfig";
import ContentProvider from "./ContentProvider";
import { CSSPlugin } from "gsap/all";
import WindowContainer from "./WindowContainer";
import * as Language from "../utilities/Language";
import {
  SojernTracking_HomePage,
  SojernTracking_Product,
  SojernTracking_Tracking,
  SojernTracking_Search,
} from "../utilities/SojernTracking";
import { trackingDisabled } from "../config/appConfig";

const Loading = () => <div className={`loadable--loading`} />;

const Footer = LoadableVisibility({
  loader: () => import("../partials/Footer" /* webpackChunkName: "footer" */),
  loading: Loading,
});

const InvalidBrowser = LoadableVisibility({
  loader: () => import("../utilities/InvalidBrowser/InvalidBrowser.js"),
  loading: Loading,
});

const Header = Loadable({
  loader: () => import("../partials/Header"),
  loading: Loading,
});

const BookingModal = LoadableVisibility({
  loader: () => import("../partials/BookingModal"),
  loading: Loading,
});
import Routes, { usesParentRoute } from "../utilities/Routes";
import Transition from "../widgets/Transition";
import { logo } from "../widgets/SVG";
import { connect } from "react-redux";
import {
  getToken,
  getRooms,
  getHotelDetails,
  getRates,
  getPromotions,
  clearError,
} from "../redux/synxis/SynxisActions";
import zenscroll from "zenscroll";
import { setLang, setCurrency } from "../redux/global/GlobalActions";
import * as Currency from "../utilities/Currency";
import SuspenseComponent from "../utilities/SuspenseComponent.js";

const CookiePopup = Loadable({
  loader: () => import("../partials/CookiePopup"),
  loading: Loading,
});

const Popup = LoadableVisibility({
  loader: () => import("../partials/Popup" /* webpackChunkName: "pop-pup" */),
  loading: Loading,
});

zenscroll.setup(500, 230);

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    CONTENT: PropTypes.object,
    viewport: PropTypes.object,
    getToken: PropTypes.func,
    getRooms: PropTypes.func,
    getHotelDetails: PropTypes.func,
    getRates: PropTypes.func,
    token: PropTypes.string,
    setLang: PropTypes.func,
    setCurrency: PropTypes.func,
    clearError: PropTypes.func,
    lang: PropTypes.string,
    step: PropTypes.number,
  };

  static childContextTypes = {
    history: PropTypes.object,
    viewport: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    toggleAccessible: PropTypes.func,
    accessible: PropTypes.bool,
    changeRoute: PropTypes.func,
    transitioning: PropTypes.object,
    exitDone: PropTypes.func,
    setLang: PropTypes.func,
    filterByLang: PropTypes.func,
    activeLangPage: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.CSS = CSSPlugin;

    this.homeTemplates = props.CONTENT.byTemplate("home");
    this.languages = this.homeTemplates.map((p) => p.linktitle);
    this.popupPage = (props.CONTENT.byTemplate("popup") || [])[0];

    this.state = {
      accessible: false,
      transitioning: {
        val: false,
        nextPath: "",
      },
    };

    this.props.history.pushTransition = this.pushTransition;
    this.setInitialLanguage();
    this.pageChangeRoutine();
  }

  getChildContext = () => ({
    history: this.props.history,
    match: this.props.match,
    location: this.props.location,
    viewport: this.props.viewport,
    toggleAccessible: this.toggleAccessible,
    accessible: this.state.accessible,
    changeRoute: this.changeRoute,
    setLang: this.props.setLang,
    transitioning: this.state.transitioning,
    exitDone: this.exitDone,
    filterByLang: this.filterByLang,
    activeLangPage: this.activeLangPage,
  });

  componentDidMount() {
    document.addEventListener("lazybeforeunveil", function (e) {
      var bg = e.target.getAttribute("data-bg");
      if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")";
      }
    });
    this.setInitialCurrency();

    this.props.getToken();

    if (this.isValidBrowser()) {
      sleep(loadingDuration).then(this.hideLoader);
    } else {
      this.hideLoader();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname, search },
    } = this.props;

    if (!prevProps.token && this.props.token) {
      this.props.getRooms();
      this.props.getHotelDetails();
      this.props.getRates();
      // this.props.getPromotions();
    }

    if (
      prevProps.location.pathname !== pathname ||
      prevProps.location.search !== search
    ) {
      this.gaTrackPageView(pathname);
      this.pageChangeRoutine();
    }

    if (prevProps.lang !== this.props.lang) {
      this.redirectToLang();
    }
  }

  setInitialLanguage = () => {
    const lang = Language.setInitialLanguage(this.languages);
    this.props.setLang(lang);

    // redirect if lang matches, but user is on a different lang page
    if (this.props.lang === lang) {
      this.redirectToLang();
    }
  };

  setInitialCurrency = () => {
    const currencyCode = Currency.getCurrency();
    this.props.setCurrency(currencyCode);
  };

  closePopup = () => this.setState({ popupVisible: false });

  redirectToLang = () => {
    const { CONTENT, location, history, lang } = this.props;

    let page = CONTENT.byPath(location.pathname);
    const search = window.location.search;
    if (page.id === 1) {
      page = CONTENT.byId(3);
    }

    if (!page.path) {
      return window.location.pathname.match(/\/es/) ? "/es" : "/";
    }

    if (
      (page.path.match(/\/es/) && lang !== "es") ||
      (!page.path.match(/\/es/) && lang === "es")
    ) {
      const nextPage = CONTENT.byId(page.related || page.canonical_ref);
      const fallback = lang === "en" ? "/" : "/es";
      history.push(nextPage.path + search || fallback);
    }
  };

  toggleBookingModal = () => {
    if (!this.state.bookingModalOpen) {
      gaTrackEvent({
        label: "Booking",
        action: "Completion",
        category: "Click",
      });
    }

    this.setState({ bookingModalOpen: !this.state.bookingModalOpen });
  };

  setLang = (lang) => this.props.setLang(lang);

  changeRoute = (nextPath) => {
    const { transitioning } = this.state;

    if (!transitioning.val && nextPath !== window.location.pathname) {
      this.setState({ transitioning: { val: true, nextPath } });
    }
  };

  // Call in the Transition onComplete callback. Signifies the exit animation
  // is complete, and we can transition to the next page.
  exitDone = () => {
    const { history, clearError } = this.props;
    const { transitioning } = this.state;
    setTimeout(() => {
      clearError();
      history.push(transitioning.nextPath);
    }, 250);

    setTimeout(() => {
      this.setState({
        transitioning: { val: false, nextPath: transitioning.nextPath },
      });
    }, 500);
  };

  hideLoader() {
    document.querySelector(".site_loader").className += " loaded";
  }

  isValidBrowser() {
    return !(browser.msie && parseInt(browser.version) < 11);
  }

  pageChangeRoutine() {
    const { location, CONTENT, lang } = this.props;

    let page = CONTENT.byPath(location.pathname);

    if (!page || page.id === 1 || !page.id) {
      page = this.props.CONTENT.byTemplate("home").find(
        (p) => p.linktitle === lang
      );
    }
    document.title = page.pagetitle;

    if (trackingDisabled) {
      return;
    } else {
      if (page.template === "home") {
        SojernTracking_HomePage();
      } else if (
        page.template === "offers" ||
        page.template === "hotels" ||
        page.template === "collections" ||
        page.template === "group_travel"
      ) {
        SojernTracking_Product();
      } else if (page.template === "las_catalinas") {
        SojernTracking_Tracking();
      } else if (page.template === "booking_results") {
        SojernTracking_Search();
      }
    }

    if (!usesParentRoute(page) || page.template === "collection_detail") {
      window.scrollTo(0, 0);
    }
  }

  gaTrackPageView(page) {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  }

  toggleAccessible = (e) => {
    e.preventDefault();
    this.setState({ accessible: !this.state.accessible }, () => {
      document.querySelector("html").classList.toggle("accessible");
    });
  };

  pushTransition = (path) => this.changeRoute(path);

  activeLangPage = (page, field, related) => {
    if (related) {
      return this.props.CONTENT.byId(page.related || page.canonical_ref)[field];
    }

    return (
      page[field] ||
      this.props.CONTENT.byId(page.related || page.canonical_ref)[field]
    );
  };

  filterByLang = (page) => {
    return this.props.lang === "es"
      ? page.path.match(/\/es/)
      : !page.path.match(/\/es/);
  };

  renderAccessibleButton = () => (
    <button
      id="accessible-link"
      tabIndex={1}
      onClick={this.toggleAccessible}
      aria-label="Accessible"
    >
      ACCESSIBLE
    </button>
  );

  renderCurtain = () => {
    return (
      <Transition
        visible={this.state.transitioning.val}
        properties={{
          opacity: [0, 1],
          autoAlpha: [0, 1],
        }}
        duration={500}
        onComplete={() => this.state.transitioning.val && this.exitDone()}
      >
        <div className="curtain">
          <div>{logo}</div>
        </div>
      </Transition>
    );
  };

  render() {
    const { location, CONTENT, viewport, lang } = this.props;

    if (this.isValidBrowser()) {
      return (
        <SuspenseComponent>
          <div className={`app`}>
            {this.renderAccessibleButton()}
            {this.renderCurtain()}
            <Header
              CONTENT={CONTENT}
              location={location}
              toggleBookingModal={this.toggleBookingModal}
              viewport={viewport}
            />
            <BookingModal
              open={this.state.bookingModalOpen}
              toggle={this.toggleBookingModal}
              CONTENT={CONTENT}
            />

            <Routes location={location} CONTENT={CONTENT} activeLang={lang} />

            <CookiePopup CONTENT={CONTENT} />

            <Popup CONTENT={CONTENT} lang={this.props.lang} />

            <Footer CONTENT={CONTENT} />
          </div>
        </SuspenseComponent>
      );
    }

    return <InvalidBrowser />;
  }
}

const mapStateToProps = ({ synxis, global }) => ({
  token: synxis.token,
  lang: global.lang,
});

const AppWithWindowContainer = WindowContainer(App);

const AppWithContent = ContentProvider(AppWithWindowContainer);

export default connect(mapStateToProps, {
  getToken,
  getRooms,
  getHotelDetails,
  getRates,
  setLang,
  setCurrency,
  clearError,
  getPromotions,
})(AppWithContent);
