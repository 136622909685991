import styles from "../../styles/partials/_tabs.scss";
import { _class, capitalize } from "../utilities/helpers";
import Reveal from "../widgets/Reveal";
import { useState, useEffect } from "react";
import { Translation } from "../utilities/Translation";

const cl = _class(styles, "tabs");

Tabs.propTypes = {
  tabs: PropTypes.array,
  active: PropTypes.object,
  onClick: PropTypes.func,
  default: PropTypes.string,
  fixedWidth: PropTypes.string,
  lang: PropTypes.string,
};

Tabs.defaultProps = {
  tabs: [],
  onClick: () => null,
};

export default function Tabs({ tabs, active, onClick, fixedWidth, lang }) {
  const LANG = new Translation(lang);
  const [width, setWidth] = useState();

  useEffect(() => {
    if (fixedWidth) {
      const items = document.querySelectorAll(`.${cl("item")}`);

      const _width = [...items].reduce((sum, item) => {
        let orig = item.style.width;
        if (item.style.width) {
          item.style.width = "";
        }

        if (item.clientWidth > sum) {
          sum = item.clientWidth;
        }

        item.style.width = orig;
        return sum;
      }, 0);

      setWidth(_width);
    }
  });

  return (
    <Reveal
      className={cl("")}
      mode="stagger"
      staggerClassName={cl("item")}
      properties={{
        x: ["-25%", "0%"],
        opacity: [0, 1],
        autoAlpha: [0, 1],
      }}
    >
      {[{ label: LANG("All"), value: LANG("All") }].concat(tabs).map((tab) => {
        let { label, value } = tab;

        const activeClass = active[value] ? styles.active : "";
        console.log("label", label);

        return (
          <button
            key={value}
            className={`${cl("item")} ${activeClass}`}
            onClick={() => onClick(tab)}
            style={{ width }}
          >
            {label &&
              label
                .split(" ")
                .map((w) => {
                  if (!w.match(/(\ba\b|\band\b|\bthe\b|\by\b)/i)) {
                    return capitalize(w);
                  }

                  return w;
                })
                .join(" ")}
          </button>
        );
      })}
    </Reveal>
  );
}
